import * as React from "react"

import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <Seo title="Diese Seite existiert leider nicht" />
    <Link to="/" className="backButton">
      <div className="icon"></div>
      <div className="label">Zurück zur Übersicht</div>
    </Link>
    <div className="topic-page">
      <div className="full-page">
        <div className="form-box">
          <h2>Diese Seite existiert leider nicht.</h2>
          <p>Bitte versuchen Sie es erneut oder kehren Sie zur Übersicht zurück.</p>
        </div>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
